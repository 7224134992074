<template>
  <div class="form-payment bg-white">
    <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
      <b-row>
        <b-col cols="3">
          <div
            style="cursor: pointer;"
            @click="prevPage"
          >
            <feather-icon
              size="24"
              class="text-dark font-weight-bolder"
              icon="ArrowLeftIcon"
            />
            <span class="text-dark text-darken-5 fw-bold-700 size14 ml-1">Kembali</span>
          </div>
        </b-col>
        <b-col cols="6">
          <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
            Lengkapi Alamat Pengiriman
          </h1>
        </b-col>
        <b-col cols="3" />
      </b-row>
    </header>
    <div class="text-center mt-5 pt-5">
      <h5 class="text-dark text-darken-4 fw-bold-800 mb-1 size14">
        Pembayaran yang diinputkan {{ parseInt(price) | formatAmount }}
      </h5>
    </div>
    <FormLengkapiAlamat
      :submit="submit"
      @payload="getPayload"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, VBToggle,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import FormLengkapiAlamat from '@/components/Payment/FormLengkapiAlamat.vue'

export default {
  title() {
    return 'Pembayaran - Tunai'
  },
  components: {
    BRow,
    BCol,
    FormLengkapiAlamat,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      price: localStorage.getItem('price'),
      formPayload: {},
    }
  },
  methods: {
    getPayload(value) {
      this.formPayload = value
    },
    submit() {
      this.$store.commit('checkout/setIsLoading', true)
      const payloadDelivery = JSON.parse(localStorage.payloadDelivery)
      this.formPayload.receiver_name = payloadDelivery.receiver_name
      this.formPayload.receiver_phone_number = payloadDelivery.receiver_phone_number
      this.formPayload.receiver_address = payloadDelivery.receiver_address
      const form = this.preparePayload()
      this.$store.dispatch('checkout/submitCheckout', {
        uuid: localStorage.getItem('cartUuid'),
        payload: form,
      }).then(result => {
        this.$store.commit('formWizard/setStep', 1)
        const item = result.data.data
        this.$router.push({ name: 'payment.success', query: { uuid: item.uuid, cash_change: item.cash_change, total: item.total } })
        localStorage.removeItem('cartUuid')
        localStorage.removeItem('customer_type')
        localStorage.removeItem('customer_uuid')
        localStorage.removeItem('cash_total_payment')
        localStorage.removeItem('payment_method')
        localStorage.removeItem('price')
        localStorage.removeItem('poSO')
        this.$store.commit('checkout/setIsLoading', false)
      }).catch(err => {
        this.$store.commit('checkout/setIsLoading', false)
        // eslint-disable-next-line no-restricted-syntax
        for (const key in err.response.data.meta.messages) {
          if (Object.hasOwnProperty.call(err.response.data.meta.messages, key)) {
            const element = err.response.data.meta.messages[key]
            errorNotification(this, 'Oops!', element.join(' '))
          }
        }
      })
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }
      return form
    },
    prevPage() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
</style>
